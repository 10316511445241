
import { Component, Vue } from 'vue-property-decorator';
import Axios from 'axios';
import LoadingScreen from '../../components/Layout/LoadingScreen.vue';

@Component({
  components: {LoadingScreen}
})
export default class Success extends Vue {
  loading: boolean = true;

  created() {
    this.$setPageTitle('Статус платежа | DOTALOOT');
  }

  mounted() {
    this.$notify({
      group: 'notification',
      duration: 10000,
      type: 'warning',
      title: 'Пополнение баланса',
      text: `К сожалению, произошла ошибка. Проверьте платежные данные или попробуйте другой способ.`
    });

    setTimeout(() => {
      this.$router.push('/');
    }, 1000);
  }
}
